.modal-content {
  border-width: 0;
  padding: 0 20px;
  border-radius: 40px !important;

}

.modal-header {
  border: none;
}

.modal-backdrop {
  background-color: darken($gray-900, 5%);

  &.show {
    opacity: .8;
  }
}