

.table {
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.05);

  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    align-items: center;
    // border-bottom: 1px solid #b5b4b4;
    // padding-left: 20px;
    // padding-right: 20px;
  }

  .title {
    height: 3.75rem;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;

    color: #b5b4b4;
  }

  .table_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.1875rem;
    font-size: 1rem;
    font-weight: 500;
    color: #b5b4b4;
    border-top-left-radius:5px;
    border-top-right-radius:5px;
  }

  .entries_box {
    width: 3.75rem;
    height: 2.1875rem;
    background-color: rgba(128, 128, 128, 0.25);;
    text-align: center;
    border: 1px solid rgba(128, 128, 128, 0.25);
    border-radius: 4px;
    margin: 1rem 0.8125rem;
    font-weight: 600;
    letter-spacing: 0.01rem;
    outline: none;
  }

  .search_box {
    width: 17.3125rem;
    height: 2.1875rem;
    border: 1px solid rgba(128, 128, 128, 0.25);
    border-radius: 4px;
    padding: 0.5625rem 0.68rem;
    font-size: 14px;
    letter-spacing: 0.01rem;
    color: var(--text);
    outline: none;
  }

  .search_box::placeholder {
    color: #7f91a8;
    font-size: 14px;
  }

  .table_wrapper {
    overflow-x: auto;
    max-height: 50vh;
  }

  .table_wrapper table {
    width: 100%;
  }

  .table_wrapper td,
  .table_wrapper th {
    font-size: 0.875rem;
    padding: 20px;
  }
  .table_wrapper td {
    color: #000000
  }
  .table_wrapper th {
    white-space: nowrap;
  }

  .table_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2.125rem;
    padding-right: 1.25rem;
    height: 5.75rem;
    border-bottom-left-radius:5px;
    border-bottom-right-radius:5px;

  }

  .pagination {
    list-style: none;
    display: flex;
  }

  .pagination_button {
    // height: 1.875rem !important;
    text-align: center !important;
    background-color: #ffffff !important;
    border: 1px solid $dark !important;
    padding: 5px 10px !important;
    color: $secondary !important;
    cursor: pointer !important;
    transition: 0.2s ease-in !important;
  }

  .pagination_button:hover {
    transform: scale(0.95) !important;
  }

  .pagination_button:first-child {
    border-radius: 4px 0px 0px 4px !important;
    color: $secondary !important;
  }

  .pagination_button:last-child {
    border-radius: 0px 4px 4px 0px !important;
    color: $secondary !important;
  }
  .pagination_button_active {
    background-color: $secondary !important;
    border: 1px solid rgba(128, 128, 128, 0.25) !important;
    color: $light !important;
  }
  tbody {
    border: none !important;
  }
  tr {
    // height: 73px !important;
    border-bottom: 1px solid rgba(196, 196, 196, 0.59) !important;
  }

  .table-head {
    // background:  rgba(11, 92, 168, 0.15) !important;
    border-bottom: none !important;
    th {
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 24px !important;
      color: #000000 !important;
    }
  }
}

@media (max-width: 577px) {
  .table {
    .search_box {
      display: none;
    }
  }
}
