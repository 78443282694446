
.Title-header {
//   height: 130px;
//   display: flex;
//   align-items: center;
  h1 {
    font-weight: 700;
    font-size: 38px;
    line-height: 58px;
    color: $dark;
  }
}
