.Property-card {
  cursor: pointer;
  margin-bottom: 50px;
  .card {
    background: #ffffff;
    border-color: #ffffff !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    position: relative;

    .Slide-image {
      border: solid 1px transparent;
      border-radius: 20px;
      height: 223.61px;
      overflow-y: hidden;

      img {
        border-radius: 20px;
        height: 100%;
      }
    }
    .Country-status {
      position: absolute;
      z-index: 2;
      top: 15px;
      left: 15px;

      .Country {
        width: auto;
        height: 29.5px;
        background-color: #ffffff;
        border-radius: 22px;
        padding: 0px 5px;
        margin-bottom: 15px;
        p {
          margin-bottom: 0px;
          font-size: 14px;
          text-transform: capitalize;
        }
        svg {
          border-radius: 22px;
        }
      }
      .Status {
        width: 91.69px;
        height: 29.5px;
        background-color: #ffffff;
        border-radius: 22px;
        padding: 0px 5px;
        p {
          margin-bottom: 0px;
          font-size: 12px;
          font-weight: 400;
        }
        svg {
          border-radius: 22px;
        }
      }
    }

    .Favorite {
      position: absolute;
      z-index: 2;
      top: 15px;
      right: 15px;
      .Fav-container {
        background-color: #ffffff;
        border-radius: 22px;
        width: 39.37px;
        height: 38.06px;
        cursor: pointer;
      }
    }
    .Sold {
      position: absolute;
      z-index: 2;
      top: 15px;
      right: 15px;
      .Sold-container {
        background-color: $success;
        border-radius: 100%;
        width: 49px;
        height: 49px;
        text-transform: uppercase;
        p {
          margin-bottom: 0;
          font-size: 14px;
          color: #ffffff;
          font-weight: 600;
        }
      }
    }
    .card-body {
      padding-left: 10px;
      .Summary {
        font-weight: 600;
        color: $secondary;
      }
      .Price-status {
        .progress {
          width: 30%;
          height: 5px;
          margin-bottom: 5px;
        }
        .Price {
          font-weight: 700;
          color: $primary;
          font-size: 18px;
          margin-bottom: 0;
        }
        .Sold-status {
          color: $secondary;
          margin-bottom: 0;
          font-size: 14px;
          height: 20px;
          line-height: 33px;
        }
      }

      .Info {
        background-color: #f6f7f9;
        border-radius: 10px;
        margin-top: 20px;
        height: 58.51px;
        padding: 0px 10px;
        .Percentage {
          color: $secondary;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 0px;
        }
        .P-label {
          color: #5e6473;
          font-weight: 300;
          font-size: 14px;
          margin-bottom: 0px;
        }
      }
      .Basic-info {
        height: 28.51px;
        margin-top: 20px;
        p {
          font-size: 14px;
          color: #5e6473;
          font-weight: 300;
          strong {
            color: $secondary;
            font-weight: 600;
          }
        }
      }
    }
  }
}

/* medium screens */
@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
}
/* large screens */
@media screen and (min-device-width: 992px) and (max-device-width: 1199px) {
  .Property-card {
    .card {
      height: 478px;
    }
  }
}
/* exrtra small screens */
@media screen and (max-width: 576px) {
}
/* Small screens */
@media screen and (min-device-width: 576px) and (max-device-width: 767px) {
}
/* Wide screens */
@media screen and (min-width: 1200px) {
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1390px) {
}
