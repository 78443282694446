.Properties {
    padding-bottom: 30px;
    .nav-link {
      width: 242px;
      height: 58px;
      font-weight: 600;
      font-size: 18px;
      border: solid 1px $primary !important ;
      margin-right: 25px;
      border-radius: 11px;
      color: $dark;
    }
  }
  
  /* medium screens */
  @media screen and (min-device-width: 768px) and (max-device-width: 991px) {
  }
  /* large screens */
  @media screen and (min-device-width: 992px) and (max-device-width: 1199px) {
  }
  /* exrtra small screens */
  @media screen and (max-width: 576px) {
    .Properties {
      .nav-pills {
        justify-content: center;
  
        .nav-link {
          margin-bottom: 20px;
          margin-right: 10px;
          margin-left: 10px;
        }
      }
    }
  }
  /* Small screens */
  @media screen and (min-device-width: 576px) and (max-device-width: 767px) {
    .Properties {
      .nav-pills {
        justify-content: center;
  
        .nav-link {
          margin-bottom: 20px;
          margin-right: 10px;
          margin-left: 10px;
        }
      }
    }
  }
  /* Wide screens */
  @media screen and (min-width: 1200px) {
  }
  @media screen and (min-device-width: 1200px) and (max-device-width: 1390px) {
  }
  