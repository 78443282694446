
.Property-details {
  padding-bottom: 30px;
  padding-top: 80px;
  // background-color: #f5f5f5;
  .Detail-section {
    h2 {
      font-weight: 700;
    }
    .card {
      box-shadow: 0px 2px 150px rgba(0, 0, 0, 0.13);
      .card-body {
        h3 {
          font-size: 24px;
          font-weight: 500;
        }
        small {
          color: #5e6473;
        }
        .btn-primary {
          width: 100% !important;
        }
      }
    }
    .Prop-details {
      width: 85%;
      p {
        font-size: 20px;
        text-align: justify;
      }
    }
    .Amenities {
      .Flex {
        margin-right: 80px !important;
        span {
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          margin-left: 20px;

          color: #000000;
        }
      }
    }
  }
  .Similar {
    position: relative;
    .Similar-list {
      overflow: auto;
      scroll-behavior: smooth;
    }
    .More {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(11, 92, 168, 0.12);
      border-radius: 20px;
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 98%;
      right: 0;
      background-color: $primary;
      margin-top: auto;
      margin-bottom: auto;
      z-index: 100000;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
    }
    .Less {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(11, 92, 168, 0.12);
      border-radius: 20px;
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: -15px;
      right: 0px;
      background-color: $primary;
      margin-top: auto;
      margin-bottom: auto;
      z-index: 100000;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
    }
  }
}

/* medium screens */
@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
}
/* large screens */
@media screen and (min-device-width: 992px) and (max-device-width: 1199px) {
}
/* exrtra small screens */
@media screen and (max-width: 576px) {
  .Property-details {
    .Save-photo {
      margin-bottom: 30px;
    }
    .Similar {
      .More {
        display: none;
      }
      .Less {
        display: none;
      }
    }
  }
}
/* Small screens */
@media screen and (min-device-width: 576px) and (max-device-width: 767px) {
  .Property-details {
    .Save-photo {
      margin-bottom: 30px;
    }
    .Similar {
      .More {
        display: none;
      }
      .Less {
        display: none;
      }
    }
  }
}
/* Wide screens */
@media screen and (min-width: 1200px) {
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1390px) {
}
