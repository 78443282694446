.Map {
  height: 300px;
  width: 600px;
}

/* medium screens */
@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
}
/* large screens */
@media screen and (min-device-width: 992px) and (max-device-width: 1199px) {
}
/* exrtra small screens */
@media screen and (max-width: 576px) {
  .Map {
    width: 100%;
  }
}
/* Small screens */
@media screen and (min-device-width: 576px) and (max-device-width: 767px) {
  .Map {
    width: 100%;
  }
}
/* Wide screens */
@media screen and (min-width: 1200px) {
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1390px) {
}
