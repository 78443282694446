/* Inter */
@font-face {
  font-family: 'Inter';
  src: local('InterThin'),
    url('./fonts/Inter/static/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Inter';
  src: local('InterExtraLight'),
    url('./fonts/Inter/static/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Inter';
  src: local('InterLight'),
    url('./fonts/Inter/static/Inter-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: local('InterRegular'),
    url('./fonts/Inter/static/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: local('InterMedium'),
    url('./fonts/Inter/static/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: local('InterSemiBold'),
    url('./fonts/Inter/static/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: local('InterBold'),
    url('./fonts/Inter/static/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: local('InterExtraBold'),
    url('./fonts/Inter/static/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Inter';
  src: local('InterBlack'),
    url('./fonts/Inter/static/Inter-Black.ttf') format('truetype');
  font-weight: 900;
}

/* Poppins */
@font-face {
  font-family: 'Poppins';
  src: local('PoppinsThin'),
    url('./fonts/Poppins/Poppins-Thin.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Poppins';
  src: local('PoppinsExtraLight'),
    url('./fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Poppins';
  src: local('PoppinsLight'),
    url('./fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Poppins';
  src: local('PoppinsRegular'),
    url('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: local('PoppinsMedium'),
    url('./fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: local('PoppinsSemiBold'),
    url('./fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: local('PoppinsBold'),
    url('./fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: local('PoppinsExtraBold'),
    url('./fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Poppins';
  src: local('PoppinsBlack'),
    url('./fonts/Poppins/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
}

/* Montserrat */

@font-face {
  font-family: 'Montserrat';
  src: local('MontserratThin'),
    url('./fonts/Montserrat/static/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Montserrat';
  src: local('MontserratExtraLight'),
    url('./fonts/Montserrat/static/Montserrat-ExtraLight.ttf')
      format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Montserrat';
  src: local('MontserratLight'),
    url('./fonts/Montserrat/static/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Montserrat';
  src: local('MontserratRegular'),
    url('./fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: local('MontserratMedium'),
    url('./fonts/Montserrat/static/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: local('MontserratSemiBold'),
    url('./fonts/Montserrat/static/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat';
  src: local('MontserratBold'),
    url('./fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Montserrat';
  src: local('MontserratExtraBold'),
    url('./fonts/Montserrat/static/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Montserrat';
  src: local('MontserratBlack'),
    url('./fonts/Montserrat/static/Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
}


body {
	font-family: 'Inter' 'Inter' !important;
}
